// plugins/i18n.js
import Vue from "vue";
import VueI18n from "vue-i18n";
import messagesCs from '~/lang/cs.json';
import messagesSk from '~/lang/sk.json';

Vue.use(VueI18n);

export default ({app, store}) => {
    // Nastavení i18n instance
    app.i18n = new VueI18n({
        locale: store.state.locale || 'cs',
        messages: {
            cs: messagesCs,
            sk: messagesSk,
        },
        pluralizationRules: {
            cs(choice, choicesLength) {
                if (choice === 0) {
                    return 0;
                }

                if (choice === 1) {
                    return 1;
                }

                if (choice >= 2 && choice <= 4) {
                    return 2;
                }

                return choicesLength < 4 ? 2 : 3;
            },
            sk(choice, choicesLength) {
                if (choice === 0) {
                    return 0;
                }

                if (choice === 1) {
                    return 1;
                }

                if (choice >= 2 && choice <= 4) {
                    return 2;
                }

                return choicesLength < 4 ? 2 : 3;
            },
            ru(choice, choicesLength) {
                // this === VueI18n instance, so the locale property also exists here

                if (choice === 0) {
                    return 0;
                }

                const teen = choice > 10 && choice < 20;
                const endsWithOne = choice % 10 === 1;

                if (choicesLength < 4) {
                    return !teen && endsWithOne ? 1 : 2;
                }
                if (!teen && endsWithOne) {
                    return 1;
                }
                if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
                    return 2;
                }

                return choicesLength < 4 ? 2 : 3;
            },
        },
    });
};
