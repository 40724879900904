import Qs from "qs";

class Jobs {
    constructor(axios) {
        this.axios = axios;
    }

    async index({ params }) {
        return await this.axios.$get("jobs", {
            params,
            paramsSerializer: params => {
                return Qs.stringify(params, {
                    arrayFormat: "brackets",
                });
            },
        });
    }

    async read({ id }) {
        return await this.axios.$get("jobs/" + id);
    }

    async similar({ id }) {
        return await this.axios.$get("jobs/" + id + "/similar");
    }
}

export default Jobs;
