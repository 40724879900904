<template>
    <div id="header_logo">
        <b-link :to="{ name: 'index' }">
            <b-img :fluid="true" class="header_image" :src="imagePath" />
        </b-link>
    </div>
</template>
<script>
import { getLogoByDomain } from "~/services/logoMapper";

import logoPracavBratislave from "@/assets/img/logo/logo_pracavbratislave.svg";
import logoPracavBystrici from "@/assets/img/logo/logo_pracavbystrici.svg";
import logoPracavIt from "@/assets/img/logo/logo_pracavit.svg";
import logoPracavKosiciach from "@/assets/img/logo/logo_pracavkosiciach.svg";
import logoPracavNitre from "@/assets/img/logo/logo_pracavnitre.svg";
import logoPracavPresove from "@/assets/img/logo/logo_pracavpresove.svg";
import logoPracavSr from "@/assets/img/logo/logo_pracavsr.svg";
import logoPracavTrencine from "@/assets/img/logo/logo_pracavtrencine.svg";
import logoPracavTrnave from "@/assets/img/logo/logo_pracavtrnave.svg";
import logoPracavZiline from "@/assets/img/logo/logo_pracavziline.svg";
import logoPracenavysocine from "~/assets/img/logo/logo_pracenavysocine.svg";
import logoPracevBrne from "~/assets/img/logo/logo_pracevbrne.svg";
import logoPracevBudejovicich from "~/assets/img/logo/logo_pracevbudejovicich.svg";
import logoPracevCr from "~/assets/img/logo/logo_pracevcr.svg";
import logoPraceveVarech from "~/assets/img/logo/logo_pracevevarech.svg";
import logoPracevHradci from "~/assets/img/logo/logo_pracevhradci.svg";
import logoPracevIt from "~/assets/img/logo/logo_pracevit.svg";
import logoPracevLiberci from "~/assets/img/logo/logo_pracevliberci.svg";
import logoPracevOlomouci from "~/assets/img/logo/logo_pracevolomouci.svg";
import logoPracevOstrave from "~/assets/img/logo/logo_pracevostrave.svg";
import logoPracevPardubicich from "~/assets/img/logo/logo_pracevpardubicich.svg";
import logoPracevPlzni from "~/assets/img/logo/logo_pracevplzni.svg";
import logoPracevPraze from "~/assets/img/logo/logo_pracevpraze.svg";
import logoPracevUsti from "~/assets/img/logo/logo_pracevusti.svg";
import logoPracevZline from "~/assets/img/logo/logo_pracevezline.svg";
import defaultLogo from "@/assets/img/logo/color.svg";

export default {
    name: "HeaderLogo",
    data() {
        return {
            logoFileName: "",
        };
    },
    computed: {
        imagePath() {
            switch (this.logoFileName) {
                case "logo_pracavbratislave.svg":
                    return logoPracavBratislave;
                case "logo_pracavbystrici.svg":
                    return logoPracavBystrici;
                case "logo_pracavit.svg":
                    return logoPracavIt;
                case "logo_pracavkosiciach.svg":
                    return logoPracavKosiciach;
                case "logo_pracavnitre.svg":
                    return logoPracavNitre;
                case "logo_pracavpresove.svg":
                    return logoPracavPresove;
                case "logo_pracavsr.svg":
                    return logoPracavSr;
                case "logo_pracavtrencine.svg":
                    return logoPracavTrencine;
                case "logo_pracavtrnave.svg":
                    return logoPracavTrnave;
                case "logo_pracavziline.svg":
                    return logoPracavZiline;
                case "logo_pracenavysocine.svg":
                    return logoPracenavysocine;
                case "logo_pracevbrne.svg":
                    return logoPracevBrne;
                case "logo_pracevbudejovicich.svg":
                    return logoPracevBudejovicich;
                case "logo_pracevcr.svg":
                    return logoPracevCr;
                case "logo_pracevevarech.svg":
                    return logoPraceveVarech;
                case "logo_pracevhradci.svg":
                    return logoPracevHradci;
                case "logo_pracevit.svg":
                    return logoPracevIt;
                case "logo_pracevliberci.svg":
                    return logoPracevLiberci;
                case "logo_pracevolomouci.svg":
                    return logoPracevOlomouci;
                case "logo_pracevostrave.svg":
                    return logoPracevOstrave;
                case "logo_pracevpardubicich.svg":
                    return logoPracevPardubicich;
                case "logo_pracevplzni.svg":
                    return logoPracevPlzni;
                case "logo_pracevpraze.svg":
                    return logoPracevPraze;
                case "logo_pracevusti.svg":
                    return logoPracevUsti;
                case "logo_pracevzline.svg":
                    return logoPracevZline;
                default:
                    return defaultLogo;
            }
        },
    },
    mounted() {
        this.logoFileName = getLogoByDomain(this.$store.state.domain);
    },
};
</script>
