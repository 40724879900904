<template>
    <div id="header_search">
        <multiselect
            v-model="search"
            :clear-on-select="false"
            :close-on-select="false"
            :custom-label="
                option => {
                    return option.name;
                }
            "
            :internal-search="true"
            :loading="loading"
            :multiple="true"
            :options="suggests"
            :searchable="true"
            class="search"
            deselect-group-label=""
            deselect-label=""
            label="name"
            placeholder="Jakou práci hledáte?"
            select-group-label=""
            select-label=""
            selected-label=""
            track-by="name"
            @select="pick"
            @search-change="find"
        >
            <template slot="option" slot-scope="props">
                <font-awesome-icon :icon="['fa', props.option.icon]" class="icon mr-2" />
                {{ props.option.name }}
            </template>

            <template slot="noOptions">
                {{ $t("header.search.noOptions") }}
            </template>

            <template slot="noResult">
                {{ $t("header.search.noOptions") }}
            </template>
        </multiselect>

        <multiselect
            v-model="location"
            :clear-on-select="false"
            :close-on-select="true"
            :custom-label="
                option => {
                    return option.label;
                }
            "
            :internal-search="true"
            :loading="false"
            :multiple="false"
            :options="$store.state.enum.locations"
            :searchable="true"
            class="location"
            deselect-group-label=""
            deselect-label=""
            label="label"
            placeholder="Oblast"
            select-group-label=""
            select-label=""
            selected-label=""
            track-by="label"
        >
            <template slot="noOptions">
                {{ $t("header.search.noOptions") }}
            </template>

            <template slot="noResult">
                {{ $t("header.search.noOptions") }}
            </template>
        </multiselect>

        <multiselect
            v-model="radius"
            :clear-on-select="false"
            :close-on-select="true"
            :custom-label="
                option => {
                    return option.label;
                }
            "
            :internal-search="false"
            :loading="false"
            :multiple="false"
            :options="radiusOptions"
            :searchable="false"
            class="radius"
            deselect-group-label=""
            deselect-label=""
            label="label"
            placeholder="Okruh"
            select-group-label=""
            select-label=""
            selected-label=""
            track-by="label"
        >
            <template slot="noOptions">
                {{ $t("header.search.noOptions") }}
            </template>

            <template slot="noResult">
                {{ $t("header.search.noOptions") }}
            </template>
        </multiselect>

        <b-btn
            class="header_button button_orange"
            @click="
                $store.dispatch('jobs/setJobsPage', {
                    value: 1,
                })
            "
        >
            <font-awesome-icon :icon="['fa', 'search']" />
        </b-btn>
    </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";

import Suggest from "~/services/suggest";

export default {
    data() {
        return {
            loading: false,
            suggests: [],
        };
    },
    computed: {
        ...mapState({
            radiusOptions: state => state.enum.radius,
        }),
        search: {
            get() {
                return this.$store.state.jobs.fields.search;
            },
            set(value) {
                this.$store.dispatch("jobs/setJobsField", {
                    field: "search",
                    value,
                });
            },
        },
        location: {
            get() {
                return this.$store.state.jobs.fields.location;
            },
            set(value) {
                this.$store.dispatch("jobs/setJobsField", {
                    field: "location",
                    value,
                });
            },
        },
        radius: {
            get() {
                return this.$store.state.jobs.fields.radius;
            },
            set(value) {
                this.$store.dispatch("jobs/setJobsField", {
                    field: "radius",
                    value,
                });
            },
        },
    },
    methods: {
        pick(option) {
            switch (option.type) {
                case "offer":
                    this.$router.replace("/inzerat/" + option.id + "-" + option.slug);

                    break;

                case "wokfield":
                    break;

                case "company":
                    break;
            }
        },
        find: _.debounce(async function (search) {
            if (search === "") {
                this.suggests = this.$store.state.enum.suggests;
            } else {
                this.loading = true;

                await new Suggest(this.$axios)
                    .search({
                        params: {
                            search,
                        },
                    })
                    .then(response => {
                        this.suggests = response.payload;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }, 500),
    },
};
</script>
