<template>
    <div id="header_menu" :class="$store.state.menu ? 'opened' : 'closed'">
        <b-nav class="header_list">
            <b-nav-item
                v-for="(item, i) in items"
                :key="i"
                :href="item.href"
                :target="item.target"
                :to="item.to"
                @click="$store.dispatch('closeMenu')"
            >
                {{ item.title }}
            </b-nav-item>
        </b-nav>
    </div>
</template>

<script>
export default {
    data() {
        return {
            items: [
                {
                    title: this.$t("header.menu.home"),
                    to: { name: "index" },
                },
                {
                    title: this.$t("header.menu.jobOffers"),
                    to: { name: "inzeraty" },
                },
                {
                    title: this.$t("header.menu.forCompanies"),
                    to: { name: "content_companies" },
                },
            ],
        };
    },
};
</script>
