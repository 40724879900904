import { render, staticRenderFns } from "./with_page_title.vue?vue&type=template&id=7a24bd50"
import script from "./with_page_title.vue?vue&type=script&lang=js"
export * from "./with_page_title.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports