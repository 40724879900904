import _ from "lodash";

import Jobs from "~/services/jobs";
import { convertToQuery, createFromQuery } from "~/utils/nestedQuery";

const defaultSearch = {
    fields: {
        search: [],
        location: null,
        radius: null,
    },
    params: {
        filters: {},
        limit: 20,
        page: 1,
    },
};

export default {
    state: () => ({
        ..._.cloneDeep(defaultSearch),
        total: 0,
        jobs: [],
        job: null,
    }),
    mutations: {
        SET_FIELDS(state, fields) {
            state.fields = fields;
        },
        SET_PARAMS(state, params) {
            state.params = params;
        },
        SET_TOTAL(state, total) {
            state.total = total;
        },
        SET_JOBS(state, jobs) {
            state.jobs = jobs;
        },
        SET_JOB(state, job) {
            state.job = job;
        },
    },
    actions: {
        initializeSearch({ commit, state, dispatch, rootState, rootGetters }, queryParamsRaw) {
            const { fields, params } = _.cloneDeep(defaultSearch);

            const queryParams = createFromQuery(queryParamsRaw);

            for (const param in params) {
                if (queryParams[param] && ["array", "object"].includes(typeof params[param]) === false) {
                    params[param] = Number(queryParams[param]);
                }
            }

            const filters = rootState.enum.filters.map(f => f.name);
            for (const filterField of filters) {
                if (queryParams.filters && queryParams.filters[filterField]) {
                    params.filters[filterField] = Number(queryParams.filters[filterField]);
                }
            }

            fields.search = [];

            if (queryParams.company) {
                const company = rootGetters["enum/getCompany"](Number(queryParams.company));
                if (company) {
                    fields.search.push(company);
                }
            }

            if (queryParams.workfield) {
                for (const workfieldId of queryParams.workfield.split(",")) {
                    const workfield = rootGetters["enum/getWorkfield"](Number(workfieldId));
                    if (workfield) {
                        fields.search.push(workfield);
                    }
                }
            }

            if (queryParams.location) {
                fields.location = rootGetters["enum/getLocation"](Number(queryParams.location));
            }

            if (queryParams.radius) {
                fields.radius = rootGetters["enum/getRadius"](Number(queryParams.radius));
            }

            commit("SET_FIELDS", fields);
            commit("SET_PARAMS", params);
            dispatch("indexJobs", {
                action: "replace",
            });
        },
        resetSearch({ commit }) {
            const { fields, params } = _.cloneDeep(defaultSearch);
            commit("SET_FIELDS", fields);
            commit("SET_PARAMS", params);
        },
        setJobsField({ commit, state }, { field, value }) {
            const fields = _.cloneDeep(state.fields);

            fields[field] = value;

            commit("SET_FIELDS", fields);
        },
        setJobsFilter({ commit, state, dispatch }, { filter, value }) {
            const params = _.cloneDeep(state.params);

            if (params.filters[filter] === value) {
                delete params.filters[filter];
            } else {
                params.filters[filter] = value;
            }

            params.page = 1;

            commit("SET_PARAMS", params);

            dispatch("indexJobs", {
                action: "replace",
            });
        },
        clearJobsFilters({ commit, state, dispatch }) {
            const params = _.cloneDeep(state.params);

            params.filters = {};

            params.page = 1;

            commit("SET_PARAMS", params);

            dispatch("indexJobs", {
                action: "replace",
            });
        },
        setJobsPage({ commit, state, dispatch }, { value }) {
            const params = _.cloneDeep(state.params);

            params.page = value;

            commit("SET_PARAMS", params);

            dispatch("indexJobs", {
                action: "replace",
            });
        },
        moreJobsPage({ commit, state, dispatch }) {
            const params = _.cloneDeep(state.params);

            params.page += 1;

            commit("SET_PARAMS", params);

            dispatch("indexJobs", {
                action: "append",
            });
        },
        async indexJobs({ commit, state, dispatch, getters }, { action }) {
            const newRoute = this.$router.resolve({
                name: "inzeraty",
                query: getters.getQueryParams,
            });

            if (this.$router.currentRoute.fullPath !== newRoute.href) {
                await this.$router.push(newRoute.location);
            }

            return await new Jobs(this.$axios)
                .index({
                    params: getters.getParams,
                })
                .then(response => {
                    let jobs = _.cloneDeep(state.jobs);

                    switch (action) {
                        case "replace":
                            jobs = response.payload;

                            break;

                        case "append":
                            jobs = jobs.concat(response.payload);

                            break;

                        case "prepend":
                            jobs = response.payload.concat(jobs);

                            break;
                    }

                    commit("SET_JOBS", jobs);

                    commit("SET_TOTAL", response.meta.entries_total);
                });
        },
        async readJob({ commit }, { id }) {
            return await new Jobs(this.$axios)
                .read({
                    id,
                })
                .then(response => {
                    commit("SET_JOB", response.payload);
                });
        },
    },
    getters: {
        getPages(state) {
            return state.total / state.params.limit;
        },
        findWorkfieldIds(state) {
            const ids = [];

            if (state.fields.search.length > 0) {
                for (const item of state.fields.search) {
                    if (item.type === "workfield") {
                        ids.push(item.id);
                    }
                }
            }

            return ids;
        },
        findCompanyId(state) {
            let id = null;

            if (state.fields.search.length > 0) {
                for (const item of state.fields.search) {
                    if (item.type === "company") {
                        id = item.id;
                    }
                }
            }

            return id;
        },
        getLocationId(state) {
            let id = null;

            if (state.fields.location) {
                id = state.fields.location.id;
            }

            return id;
        },
        getRadiusValue(state) {
            let value = null;

            if (state.fields.radius) {
                value = state.fields.radius.value;
            }

            return value;
        },
        getParams(state, getters) {
            const params = {};

            params.filters = state.params.filters;

            params.limit = state.params.limit;

            params.page = state.params.page;

            if (getters.findWorkfieldIds.length > 0) {
                params.workfield = getters.findWorkfieldIds.join(",");
            }

            if (getters.findCompanyId != null) {
                params.company = getters.findCompanyId;
            }

            if (getters.getLocationId != null) {
                params.location = getters.getLocationId;
            }

            if (getters.getRadiusValue != null) {
                params.radius = getters.getRadiusValue;
            }

            return params;
        },
        getQueryParams(state, getters) {
            const queryParams = _.cloneDeep(getters.getParams);

            // remove default values
            for (const param in defaultSearch.params) {
                if (queryParams[param] && queryParams[param] === defaultSearch.params[param]) {
                    delete queryParams[param];
                }
            }

            return convertToQuery(queryParams);
        }
    },
};
