import { render, staticRenderFns } from "./ForEmployer.vue?vue&type=template&id=36160d8d"
import script from "./ForEmployer.vue?vue&type=script&lang=js"
export * from "./ForEmployer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports