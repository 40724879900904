import Enum from "~/services/enum";

export default {
    state: () => ({
        suggests: [],
        locations: [],
        radius: [],
        companies: [],
        workfields: [],
        filters: [],
        loaded: false,
    }),
    mutations: {
        SET_SUGGESTS(state, suggests) {
            state.suggests = suggests;
        },
        SET_LOCATIONS(state, locations) {
            state.locations = locations;
        },
        SET_RADIUS(state, radius) {
            state.radius = radius;
        },
        SET_COMPANIES(state, companies) {
            state.companies = companies;
        },
        SET_WORKFIELDS(state, workfields) {
            state.workfields = workfields;
        },
        SET_FILTERS(state, filters) {
            state.filters = filters;
        },
        SET_LOADED(state, loaded) {
            state.loaded = loaded;
        },
    },
    actions: {
        async indexCache({ commit, state }) {
            if (state.loaded) {
                return Promise.resolve();
            }

            return await new Enum(this.$axios).cache().then(response => {
                commit("SET_LOADED", true);
                commit("SET_SUGGESTS", response.payload.suggests); // TODO REC-4649
                commit("SET_LOCATIONS", response.payload.locations);
                commit("SET_RADIUS", response.payload.radius);
                commit("SET_COMPANIES", response.payload.companies);
                commit("SET_WORKFIELDS", response.payload.workfields);
                commit("SET_FILTERS", response.payload.filters);
            });
        },
    },
    getters: {
        getLocation: state => locationId => {
            return state.locations.find(location => location.id === locationId);
        },
        getRadius: state => radiusValue => {
            return state.radius.find(r => r.value === radiusValue);
        },
        getCompany: state => companyId => {
            return state.companies.find(company => company.id === companyId);
        },
        getWorkfield: state => workfieldId => {
            return state.workfields.find(workfield => workfield.id === workfieldId);
        },
    },
};
