<template>
    <div id="header" :class="classLayout">
        <b-container id="header_up">
            <b-row align-v="center">
                <b-col class="d-block d-lg-none" cols="12" lg="auto">
                    <ToggleMenu />
                </b-col>

                <b-col cols="12" lg="3">
                    <Logo />
                </b-col>

                <b-col cols="12" lg="9">
                    <Menu />
                </b-col>
            </b-row>
        </b-container>

        <b-container id="header_down">
            <slot />
        </b-container>
    </div>
</template>

<script>
import Menu from "~/components/Header/Menu";
import Logo from "~/components/Header/Logo";
import ToggleMenu from "~/components/Header/ToggleMenu";

export default {
    components: {
        Menu,
        Logo,
        ToggleMenu,
    },
    props: {
        classLayout: {
            type: String,
            default: "",
        },
    },
};
</script>
