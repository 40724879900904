<template>
    <div id="header_job_title">
        <b-nav class="breadcrumb_list">
            <b-nav-item :to="{ name: 'inzeraty', query: searchParams }">
                <font-awesome-icon :icon="['fa', 'caret-left']" />
                {{ $t("jobs.nav.back") }}
            </b-nav-item>
        </b-nav>

        <h1 class="header_title">
            {{ jobDetail.title }}
        </h1>

        <b-btn class="header_button button_blue" @click="goToAnswer()">
            {{ $t("jobs.nav.answerForm") }}
        </b-btn>

        <b-btn
            v-for="share in shareLinks"
            :key="share.site"
            class="header_button button_link button_share"
            :href="share.url"
            :title="$t('jobs.nav.share.' + share.site)"
            target="_blank"
        >
            <font-awesome-icon :icon="[share.iconPack, share.icon]" size="lg" />
        </b-btn>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
    head() {
        return {
            meta: [
                ...Object.keys(this.jobDetail.open_graph_tags ?? {}).map(tag => {
                    const content = this.jobDetail.open_graph_tags[tag];
                    return {
                        hid: tag,
                        property: tag,
                        content,
                    };
                }),
                { hid: "og:url", property: "og:url", content: this.fullUrl },
            ],
        };
    },
    computed: {
        ...mapState({
            jobDetail: state => state.jobs.job,
        }),
        ...mapGetters({
            searchParams: "jobs/getQueryParams",
        }),
        fullUrl() {
            const route = this.$router.resolve({
                name: 'inzerat',
                params: {
                    id: this.jobDetail.id,
                    slug: this.jobDetail.slug,
                },
            })

            return (process.client ? window.location.origin : this.$store.state.origin) + route.href;
        },
        shareLinks() {
            return [
                ...Object.keys(this.jobDetail.share_links ?? {}).map(site => {
                    let icon = site;
                    let iconPack = "fab";
                    if (site === "twitter") {
                        icon = "x-twitter";
                    } else if (site === "email") {
                        icon = "envelope";
                        iconPack = "fa";
                    }
                    return {
                        site,
                        url: this.jobDetail.share_links[site].replace("{URL}", this.fullUrl),
                        iconPack,
                        icon,
                    };
                }),
            ];
        },
    },
    methods: {
        goToAnswer() {
            document.getElementById("answer").scrollIntoView({
                behavior: "smooth",
            });
        },
    },
};
</script>
