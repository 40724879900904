export default {
    state: () => ({
        menu: false,
        filter: false,
        origin: null,
        locale: null,
        domain: null,
    }),
    mutations: {
        SET_MENU(state, menu) {
            state.menu = menu;
        },
        SET_FILTER(state, filter) {
            state.filter = filter;
        },
        SET_ORIGIN(state, origin) {
            state.origin = origin;
        },
        SET_LOCALE(state, locale) {
            state.locale = locale;
        },
        SET_DOMAIN(state, domain) {
            state.domain = domain;
        }
    },
    actions: {
        openMenu({ commit }) {
            commit("SET_MENU", true);
        },
        closeMenu({ commit }) {
            commit("SET_MENU", false);
        },
        toggleMenu({ commit, state }) {
            commit("SET_MENU", !state.menu);
        },
        openFilter({ commit }) {
            commit("SET_FILTER", true);
        },
        closeFilter({ commit }) {
            commit("SET_FILTER", false);
        },
        toggleFilter({ commit, state }) {
            commit("SET_FILTER", !state.filter);
        },
        nuxtServerInit({ commit }, { req: { session }, app }) {
            // set locale from session - i18n plugin is already initialized with wrong locale 🤷‍
            app.i18n.locale = session.locale;

            return Promise.all([
                commit("SET_ORIGIN", session.origin),
                commit("SET_LOCALE", session.locale),
                commit("SET_DOMAIN", session.domain),
            ]);
        },
    },
    getters: {},
};
