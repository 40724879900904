<template>
    <div id="footer_contact">
        <h3 class="footer_title">
            {{ $t("footer.contact") }}
        </h3>

        <b-nav class="footer_list">
            <b-nav-text v-for="line in contact" :key="line"><span>{{ line }}</span></b-nav-text>
            <b-nav-item v-for="(item, i) in items" :key="i" :href="item.href" :target="item.target" :to="item.to">
                {{ item.key }}
            </b-nav-item>
        </b-nav>
    </div>
</template>

<script>
export default {
    data() {
        return {
            contact: [
                this.$t("footer.address.companyName"),
                this.$t("footer.address.street"),
                this.$t("footer.address.city"),
                this.$t("footer.ico"),
                this.$t("footer.dic"),
            ],
            items: [
                {
                    key: this.$t("footer.email"),
                    href: "mailto: " + this.$t("footer.email"),
                },
                {
                    key: this.$t("footer.phone"),
                    href: "tel: " + this.$t("footer.phone"),
                },
            ],
        };
    },
};
</script>
