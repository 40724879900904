<template>
    <div id="footer_portals">
        <h3 class="footer_title">
            {{ $t("footer.portals") }}
        </h3>

        <b-nav class="footer_list">
            <b-nav-item v-for="item in items" :key="item.title" :href="item.href" :target="item.target" :to="item.to">
                {{ item.title }}
            </b-nav-item>
        </b-nav>
    </div>
</template>

<script>
import { mapState } from 'vuex';

const portals = [
    {
        href: 'https://pracevcr.cz',
        title: 'Práce v ČR .cz',
    },
    {
        href: 'https://pracevpraze.cz',
        title: 'Práce v Praze .cz',
    },
    {
        href: 'https://prace-brno.cz',
        title: 'Práce - Brno .cz',
    },
    {
        href: 'https://pracevhradci.cz',
        title: 'Práce v Hradci .cz',
    },
    {
        href: 'https://pracenavysocine.cz',
        title: 'Práce na Vysočině .cz',
    },
    {
        href: 'https://pracevbudejovicich.cz',
        title: 'Práce v Budějovicích .cz',
    },
    {
        href: 'https://pracevevarech.cz',
        title: 'Práce ve Varech .cz',
    },
    {
        href: 'https://pracevezline.cz',
        title: 'Práce ve Zlíně .cz',
    },
    {
        href: 'https://pracevliberci.cz',
        title: 'Práce v Liberci .cz',
    },
    {
        href: 'https://pracevolomouci.cz',
        title: 'Práce v Olomouci .cz',
    },
    {
        href: 'https://pracevostrave.cz',
        title: 'Práce v Ostravě .cz',
    },
    {
        href: 'https://pracevpardubicich.cz',
        title: 'Práce v Pardubicích .cz',
    },
    {
        href: 'https://pracevplzni.cz',
        title: 'Práce v Plzni .cz',
    },
    {
        href: 'https://pracevusti.cz',
        title: 'Práce v Ústí .cz',
    },
    {
        href: 'https://pracavsr.sk',
        title: 'Práca na Slovensku .sk',
    },
    {
        href: 'https://pracavnitre.sk',
        title: 'Práca v Nitre .sk',
    },
    {
        href: 'https://pracavkosiciach.sk',
        title: 'Práca v Košiciach .sk',
    },
    {
        href: 'https://pracavpresove.sk',
        title: 'Práca v Prešove .sk',
    },
    {
        href: 'https://pracavtrencine.sk',
        title: 'Práca v Trenčíne .sk',
    },
    {
        href: 'https://pracavtrnave.sk',
        title: 'Práca v Trnave .sk',
    },
    {
        href: 'https://pracavziline.sk',
        title: 'Práca v Žiline .sk',
    },
    {
        href: 'https://pracavbratislave.sk',
        title: 'Práca v Bratislave .sk',
    },
    {
        href: 'https://pracavbystrici.sk',
        title: 'Práca v Banskej Bystrici .sk',
    },
    {
        href: 'https://pracevit.cz',
        title: 'Práce v IT .cz',
    },
    {
        href: 'https://pracavit.sk',
        title: 'Práca v IT .sk',
    },
];

export default {
    computed: {
        ...mapState(['domain']),
        items() {
            return portals
                .filter(domain => domain.href.includes(this.domain) === false);
        }
    }
};
</script>
