<template>
    <b-container class="section">
        <b-row align-v="center">
            <b-col cols="12" lg="9">
                <div class="subsection">
                    <h3 class="banner_title">
                        {{ errorTitle }}
                    </h3>
                    <p class="banner_paragraph">
                        {{ errorMessage }}
                    </p>
                </div>
            </b-col>

            <b-col cols="12" lg="3" />
        </b-row>
    </b-container>
</template>

<script>
export default {
    layout: "error",
    props: {
        error: {
            type: Object,
            required: true,
        },
    },
    computed: {
        errorTitle() {
            const statusCode = this.error.statusCode;
            return this.$t(`errors.${statusCode || "default"}.title`);
        },
        errorMessage() {
            const statusCode = this.error.statusCode;
            return this.$t(`errors.${statusCode || "default"}.message`);
        },
    },
};
</script>
