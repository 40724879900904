<template>
    <div id="footer" :class="classLayout">
        <b-container id="footer_up">
            <b-row align-v="start">
                <b-col cols="12" lg="5" order="4" order-lg="1">
                    <AboutUs />
                </b-col>

                <b-col cols="12" lg="3" offset-lg="1" order="2" order-lg="3">
                    <ForEmployer />
                    <ForEmployee />
                </b-col>

                <b-col cols="12" lg="3" order="3" order-lg="4">
                    <Contact />
                </b-col>
            </b-row>
            <b-row align-v="start">
                <b-col cols="12">
                    <Portals />
                </b-col>
            </b-row>
        </b-container>

        <hr class="footer_line d-none d-lg-block" />

        <b-container id="footer_down">
            <b-row align-v="center">
                <b-col cols="12" lg="2" order="2" order-lg="1">
                    <Logo />
                </b-col>

                <b-col cols="12" lg="6" order="1" order-lg="2">
                    <Menu />
                </b-col>

                <b-col cols="12" lg="4" order="3">
                    <Copyright />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import AboutUs from "~/components/Footer/AboutUs";
import Contact from "~/components/Footer/Contact";
import Copyright from "~/components/Footer/Copyright";
import ForEmployee from "~/components/Footer/ForEmployee";
import ForEmployer from "~/components/Footer/ForEmployer";
import Logo from "~/components/Footer/Logo";
import Menu from "~/components/Footer/Menu";
import Portals from "~/components/Footer/Portals.vue";

export default {
    components: {
        Portals,
        AboutUs,
        Contact,
        Copyright,
        ForEmployee,
        ForEmployer,
        Logo,
        Menu,
    },
    props: {
        classLayout: {
            type: String,
            default: "",
        },
    },
};
</script>
