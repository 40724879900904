import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6170c9a8 = () => interopDefault(import('../pages/job.vue' /* webpackChunkName: "pages/job" */))
const _1d576b77 = () => interopDefault(import('../pages/jobs.vue' /* webpackChunkName: "pages/jobs" */))
const _a06ba5a0 = () => interopDefault(import('../pages/page.vue' /* webpackChunkName: "pages/page" */))
const _bdae2218 = () => interopDefault(import('../pages/content/companies.vue' /* webpackChunkName: "pages/content/companies" */))
const _0fa447cf = () => interopDefault(import('../pages/content/terms_and_conditions.vue' /* webpackChunkName: "pages/content/terms_and_conditions" */))
const _17a0a04e = () => interopDefault(import('../pages/content/terms_of_use.vue' /* webpackChunkName: "pages/content/terms_of_use" */))
const _3169c7fe = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/job",
    component: _6170c9a8,
    name: "job"
  }, {
    path: "/jobs",
    component: _1d576b77,
    name: "jobs"
  }, {
    path: "/page",
    component: _a06ba5a0,
    name: "page"
  }, {
    path: "/content/companies",
    component: _bdae2218,
    name: "content-companies"
  }, {
    path: "/content/terms_and_conditions",
    component: _0fa447cf,
    name: "content-terms_and_conditions"
  }, {
    path: "/content/terms_of_use",
    component: _17a0a04e,
    name: "content-terms_of_use"
  }, {
    path: "/",
    component: _3169c7fe,
    name: "index"
  }, {
    path: "/inzeraty",
    component: _1d576b77,
    name: "inzeraty"
  }, {
    path: "/inzerat/:id-:slug",
    component: _6170c9a8,
    name: "inzerat"
  }, {
    path: "/stranka/:slug",
    component: _a06ba5a0,
    name: "stranka"
  }, {
    path: "/pro-firmy",
    component: _bdae2218,
    name: "content_companies"
  }, {
    path: "/vseobecne-obchodni-podminky",
    component: _0fa447cf,
    name: "content_terms_and_conditions"
  }, {
    path: "/podminky-uzivani",
    component: _0fa447cf,
    name: "content_terms_of_use"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
