import _ from "lodash";

import Hot from "~/services/hot";

export default {
    state: () => ({
        paramsJobs: {
            workfield: null,
        },
        paramsWorkfields: {
            limit: 10,
        },
        paramsEmployers: {
            limit: 10,
        },
        jobs: [],
        workfields: [],
        employers: [],
    }),
    mutations: {
        SET_PARAMS_JOBS(state, paramsJobs) {
            state.paramsJobs = paramsJobs;
        },
        SET_PARAMS_WORKFIELDS(state, paramsWorkfields) {
            state.paramsWorkfields = paramsWorkfields;
        },
        SET_PARAMS_EMPLOYERS(state, paramsEmployers) {
            state.paramsEmployers = paramsEmployers;
        },
        SET_JOBS(state, jobs) {
            state.jobs = jobs;
        },
        SET_WORKFIELDS(state, workfields) {
            state.workfields = workfields;
        },
        SET_EMPLOYERS(state, employers) {
            state.employers = employers;
        },
    },
    actions: {
        setHotJobsWorkfield({ commit, state, dispatch }, { value }) {
            const paramsJobs = _.cloneDeep(state.paramsJobs);

            paramsJobs.workfield = value;

            commit("SET_PARAMS_JOBS", paramsJobs);

            dispatch("indexHotJobs");
        },
        async indexHotJobs({ commit, getters }) {
            return await new Hot(this.$axios)
                .jobs({
                    params: getters.getParamsJobs,
                })
                .then(response => {
                    commit("SET_JOBS", response.payload);
                });
        },
        async indexHotWorkfields({ commit, getters }) {
            return await new Hot(this.$axios)
                .workfields({
                    params: getters.getParamsWokfields,
                })
                .then(response => {
                    commit("SET_WORKFIELDS", response.payload);
                });
        },
        async indexHotEmployers({ commit, getters }) {
            return await new Hot(this.$axios)
                .employers({
                    params: getters.getParamsEmployers,
                })
                .then(response => {
                    commit("SET_EMPLOYERS", response.payload);
                });
        },
    },
    getters: {
        getParamsJobs(state) {
            const paramsJobs = {};

            if (state.paramsJobs.workfield !== null) {
                paramsJobs.workfield = state.paramsJobs.workfield;
            }

            return paramsJobs;
        },
        getParamsWokfields(state) {
            const paramsWorkfields = {};

            paramsWorkfields.limit = state.paramsWorkfields.limit;

            return paramsWorkfields;
        },
        getParamsEmployers(state) {
            const paramsEmployers = {};

            paramsEmployers.limit = state.paramsEmployers.limit;

            return paramsEmployers;
        },
    },
};
