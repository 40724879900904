<template>
    <div>
        <Header class-layout="with_page_title">
            <b-row align-v="start">
                <b-col cols="12" lg="8">
                    <PageTitle />
                </b-col>

                <b-col cols="12" lg="4" />
            </b-row>
        </Header>

        <Nuxt />

        <Footer />
    </div>
</template>

<script>
import Header from "~/components/Header";
import PageTitle from "~/components/Header/PageTitle";
import Footer from "~/components/Footer";

export default {
    components: {
        Header,
        PageTitle,
        Footer,
    },
};
</script>
