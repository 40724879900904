export default function ({ $axios, $config, store }) {
    $axios.onRequest(config => {
        if (process.server) {
            config.headers.common.origin = store.state.origin;
        }

        if ($config.axiosHeaderDomainOverwrite) {
            config.headers.common["X-Portal-Domain"] = $config.axiosHeaderDomainOverwrite;
        }

        config.headers.common["Accept-Language"] = store.state.locale;
    });
}
