import Qs from "qs";

export default axios => ({
    async jobs({ params }) {
        return await axios.$get("hot/jobs", {
            params,
            paramsSerializer: params => {
                return Qs.stringify(params, {
                    arrayFormat: "brackets",
                });
            },
        });
    },
    async workfields({ params }) {
        return await axios.$get("hot/workfields", {
            params,
            paramsSerializer: params => {
                return Qs.stringify(params, {
                    arrayFormat: "brackets",
                });
            },
        });
    },
    async employers({ params }) {
        return await axios.$get("hot/employers", {
            params,
            paramsSerializer: params => {
                return Qs.stringify(params, {
                    arrayFormat: "brackets",
                });
            },
        });
    },
});
