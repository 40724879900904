<template>
    <div id="header_page_title">
        <h1 class="header_title">{{ title }}</h1>
    </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            title: state => state.page.title,
        }),
    },
};
</script>
