export default {
    state: () => ({
        title: null,
    }),
    mutations: {
        SET_TITLE(state, title) {
            state.title = title;
        },
    },
    actions: {
        setTitle({ commit }, title) {
            commit("SET_TITLE", title);
        },
    },
};
