<template>
    <div>
        <Header class-layout="with_search">
            <b-row align-v="start">
                <b-col cols="12" lg="8">
                    <Search />
                </b-col>

                <b-col cols="12" lg="4" />
            </b-row>

            <b-row align-v="center">
                <b-col class="d-block d-lg-none" cols="12" lg="12">
                    <ToggleFilter />
                </b-col>
            </b-row>
        </Header>

        <Nuxt />

        <Footer />
    </div>
</template>

<script>
import Header from "~/components/Header";
import Search from "~/components/Header/Search";
import ToggleFilter from "~/components/Header/ToggleFilter";
import Footer from "~/components/Footer";

export default {
    components: {
        Header,
        Search,
        ToggleFilter,
        Footer,
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.loadData();
        },
        loadData() {
            this.$store.dispatch("enum/indexCache");
        },
    },
};
</script>
