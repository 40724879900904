<template>
    <div>
        <Header class-layout="with_job_title">
            <b-row align-v="start">
                <b-col cols="12" lg="8">
                    <JobTitle />
                </b-col>

                <b-col cols="12" lg="4" />
            </b-row>
        </Header>

        <Nuxt />

        <Footer />
    </div>
</template>

<script>
import Header from "~/components/Header";
import JobTitle from "~/components/Header/JobTitle";
import Footer from "~/components/Footer";

export default {
    components: {
        Header,
        JobTitle,
        Footer,
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.loadData();
        },
        loadData() {
            this.$store.dispatch("enum/indexCache");
        },
    },
};
</script>
