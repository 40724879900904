import Qs from "qs";

/**
 * Converts a nested object into a query string and then back into a flattened object.
 *
 * This function takes an object with potentially nested properties and converts it into a query string using the "brackets" format for arrays.
 * It then parses this query string back into an object, with nested properties represented in a flattened manner using bracket notation.
 *
 * For example, an input of { object: { nested: 1 } } would be converted into a query string "object[nested]=1",
 * which is then parsed back into an object of the form { "object[nested]": "1" }.
 *
 * @param {Object} queryParams - The object with nested properties to be converted.
 * @returns {Object} - An object representing the query parameters, with nested properties flattened.
 */
export function convertToQuery(queryParams) {
    const queryString = Qs.stringify(queryParams, {
        arrayFormat: "brackets",
    });

    const searchParams = new URLSearchParams(queryString);
    const query = {};
    for (const [key, value] of searchParams) {
        query[key] = value;
    }

    return query;
}

/**
 * Converts a query parameters object with flattened keys back into a nested object.
 *
 * This function processes an object where keys are in a flattened format (using bracket notation) and converts it into a nested object.
 * It leverages URLSearchParams to handle the query parameter string and then parses it back into a nested object using Qs.parse.
 *
 * For example, an input of { "object[nested]": "1" } would be converted into a nested object { object: { nested: 1 } }.
 *
 * @param {Object} queryParams - The object with flattened query parameter keys to be converted.
 * @returns {Object} - A nested object representing the original structure of the query parameters.
 */
export function createFromQuery(queryParams) {
    const searchParams = new URLSearchParams(queryParams);
    return Qs.parse(searchParams.toString());
}
