<template>
    <div id="footer_for_employer">
        <h3 class="footer_title">
            {{ $t("footer.employers.title") }}
        </h3>

        <b-nav class="footer_list">
            <b-nav-item v-for="(item, i) in items" :key="i" :href="item.href" :target="item.target" :to="item.to">
                {{ item.title }}
            </b-nav-item>
        </b-nav>
    </div>
</template>

<script>
export default {
    data() {
        return {
            items: [
                {
                    title: this.$t("footer.employers.advertisingRates"),
                    to: { name: "content_companies" },
                },
                {
                    title: this.$t("footer.employers.postAJob"),
                    href: "https://app.recruitis.io/zadavatel",
                    target: "_blank",
                },
            ],
        };
    },
};
</script>
