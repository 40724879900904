import Qs from "qs";

class Suggest {
    constructor(axios) {
        this.axios = axios;
    }

    async search({ params }) {
        return await this.axios.$get("suggest/search", {
            params,
            paramsSerializer: params => {
                return Qs.stringify(params, {
                    arrayFormat: "brackets",
                });
            },
        });
    }
}

export default Suggest;
