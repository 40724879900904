import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import languageCs from "../lang/cookieConsent.cs.json";
import languageSk from "../lang/cookieConsent.sk.json";

export default async ({ app }, inject) => {
    const config = {
        root: null,
        autoShow: true,
        autoClearCookies: true,
        guiOptions: {
            consentModal: {
                layout: "cloud",
                position: "bottom center",
            },
            preferencesModal: {
                layout: "box",
            },
        },
        categories: {
            necessary: {
                enabled: true,
                readOnly: true,
            },
            analytics: {
                enabled: false,
                autoClear: {
                    cookies: [
                        {
                            name: /^_ga_*/,
                        },
                        {
                            name: /^_ga/,
                        },
                        {
                            name: "_gid",
                        },
                        {
                            name: "session",
                        },
                    ],
                },
            },
        },
        language: {
            default: app.i18n.locale,
            translations: {
                cs: languageCs,
                sk: languageSk,
            },
        },
    };

    await CookieConsent.run(config);

    inject("cookieConsent", CookieConsent);
};
