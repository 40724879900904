/**
 * @param  {string} domain
 * @return {string|null} google analytics id
 */
export const getGoogleAnalyticsByDomain = domain => {
    let googleAnalyticsId = null;
    const data = {
        "pracevcr.cz": "G-H0BDPYHQE1",
        "prace-brno.cz": "G-JPS0G3KBMN",
        "pracevit.cz": "G-MEN0NYCMGC",
        "pracevpardubicich.cz": "G-9Z6R47FF1H",
        "pracevpraze.cz": "G-SLPQDZ01VD",
        "pracenavysocine.cz": "G-16359STB5X",
        "pracevbudejovicich.cz": "G-DWD6KLGPKS",
        "pracevhradci.cz": "G-TBK707PG8T",
        "pracevostrave.cz": "G-LBBJ4DQ92R",
        "pracevevarech.cz": "G-ZDN1X19Y3P",
        "pracevezline.cz": "G-N36P9512H7",
        "pracevliberci.cz": "G-H6CQM0BFMQ",
        "pracevolomouci.cz": "G-JMY1QVLBDB",
        "pracevplzni.cz": "G-T3WXHZGCR5",
        "pracevusti.cz": "G-QKL7PTREPQ",
        "pracavnitre.sk": "G-WH59QF7E9X",
        "pracavbystrici.sk": "G-9V7R8DD7X2",
        "pracavziline.sk": "G-Y1X81X54GC",
        "pracavtrencine.sk": "G-YMP3DLV1SC",
        "pracavtrnave.sk": "G-F60M8X5LT0",
        "pracavkosiciach.sk": "G-9YB8130VZM",
        "pracavsr.sk": "G-VQQK2GNCVX",
        "pracavit.sk": "G-QRYR46TD7X",
        "pracavpresove.sk": "G-7Q2RFZCB9P",
        "pracavbratislave.sk": "G-P1H53WPZCZ",
    };

    for (const [correspondingDomain, id] of Object.entries(data)) {
        if (domain.toLowerCase() === correspondingDomain) {
            googleAnalyticsId = id;
            break;
        }
    }
    return googleAnalyticsId;
};
