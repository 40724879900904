<template>
    <div>
        <Header class-layout="with_search">
            <b-row align-v="start">
                <b-col cols="12" lg="8">
                    <Search />
                </b-col>

                <b-col cols="12" lg="4" />
            </b-row>
        </Header>

        <Nuxt />

        <Footer />
    </div>
</template>

<script>
import Header from "~/components/Header";
import Search from "~/components/Header/Search";
import Footer from "~/components/Footer";

export default {
    components: {
        Header,
        Search,
        Footer,
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.loadData();
        },
        loadData() {
            this.$store.dispatch("enum/indexCache");
        },
    },
};
</script>
