<template>
    <div id="footer_menu">
        <b-nav class="footer_list">
            <b-nav-item v-for="(item, i) in items" :key="i" :href="item.href" :target="item.target" :to="item.to">
                {{ item.title }}
            </b-nav-item>
        </b-nav>
    </div>
</template>

<script>
export default {
    data() {
        return {
            items: [
                {
                    title: this.$t("footer.menu.termsOfUse"),
                    to: { name: "content_terms_of_use" },
                },
                {
                    title: this.$t("footer.menu.generalBusinessTerms"),
                    to: { name: "content_terms_and_conditions" },
                },
                {
                    title: this.$t("footer.menu.recruitisCompany"),
                    href: "https://recruitis.io/",
                    target: "_blank",
                },
            ],
        };
    },
};
</script>
