import { getGoogleAnalyticsByDomain } from "~/services/googleAnalyticsMapper";

export default ({ app }) => {
    const analyticsId = getGoogleAnalyticsByDomain(window.location.hostname);
    if (!analyticsId) {
        return;
    }

    // Kontrola, zda skript již existuje v DOM
    const isGACodeExists = Boolean(
        document.querySelector(`head > script[src="https://www.googletagmanager.com/gtag/js?id=${analyticsId}"]`),
    );

    if (!isGACodeExists) {
        // Vložení skriptu, pokud ještě není v DOM
        const myScript = document.createElement("script");
        myScript.type = "text/javascript";
        myScript.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;
        myScript.async = true;
        document.head.appendChild(myScript);
    }

    // Inicializace Google Analytics
    window.dataLayer = window.dataLayer || [];

    function gtag() {
        window.dataLayer.push(arguments);
    }

    gtag("js", new Date());
    gtag("config", analyticsId, { cookie_flags: "SameSite=None;Secure" });
};
