<template>
    <div id="footer_for_employee">
        <h3 class="footer_title">
            {{ $t("footer.employees.title") }}
        </h3>

        <b-nav class="footer_list">
            <b-nav-item v-for="item in items" :key="item.title" :href="item.href" :target="item.target" :to="item.to">
                {{ item.title }}
            </b-nav-item>
        </b-nav>
    </div>
</template>

<script>
export default {
    data() {
        return {
            items: [
                {
                    title: this.$t("footer.employees.jobSearching"),
                    to: { name: "inzeraty" },
                },
            ],
        };
    },
};
</script>
