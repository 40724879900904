<template>
    <div id="footer_about_us">
        <h3 class="footer_title">
            {{ $t("footer.aboutUs") }}
        </h3>

        <p class="footer_paragraph">
            {{ $t("footer.aboutParagraph") }}
        </p>

        <b-nav class="footer_list">
            <b-nav-item v-for="(item, i) in items" :key="i" :href="item.href" :target="item.target" :to="item.to">
                {{ item.title }}
            </b-nav-item>
        </b-nav>
    </div>
</template>

<script>
export default {
    data() {
        return {
            items: [
                {
                    title: this.$t("footer.moreAboutRecruitis"),
                    href: "https://recruitis.io",
                    target: "_blank",
                },
            ],
        };
    },
};
</script>
