<template>
    <div id="footer_copyright">
        <p class="footer_paragraph">{{ $t("footer.copyright", { currentYear: currentYear }) }}</p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    },
};
</script>
