/**
 * logo file name based on domain
 *
 * @param  {string} domain
 * @return {string} the logo pathway
 */
export const getLogoByDomain = domain => {
    let logo = "color.svg";
    const data = {
        "logo_pracavbratislave.svg": ["pracavbratislave.sk", "pracevbratislave.sk"],
        "logo_pracavbystrici.svg": ["pracavbystrici.sk", "pracevbystrici.sk"],
        "logo_pracavit.svg": ["pracavit.sk"],
        "logo_pracavkosiciach.svg": [
            "pracavkosiciach.sk",
            "pracevkosiciach.sk",
            "pracavkosicich.sk",
            "pracevkosicich.sk",
        ],
        "logo_pracavnitre.svg": ["pracavnitre.sk", "pracevnitre.sk"],
        "logo_pracavpresove.svg": ["pracavpresove.sk", "pracevpresove.sk"],
        "logo_pracavsr.svg": ["pracavsr.sk", "pracevsr.sk", "dev.pracavsr.sk"],
        "logo_pracavtrencine.svg": ["pracavtrencine.sk", "pracevtrencine.sk"],
        "logo_pracavtrnave.svg": ["pracavtrnave.sk", "pracevtrnave.sk"],
        "logo_pracavziline.svg": ["pracavziline.sk", "pracevziline.sk"],
        "logo_pracenavysocine.svg": ["pracenavysocine.cz"],
        "logo_pracevbrne.svg": ["prace-brno.cz"],
        "logo_pracevbudejovicich.svg": ["pracevbudejovicich.cz"],
        "logo_pracevcr.svg": ["pracevcr.cz"],
        "logo_pracevevarech.svg": ["pracevevarech.cz"],
        "logo_pracevhradci.svg": ["pracevhradci.cz"],
        "logo_pracevit.svg": ["pracevit.cz"],
        "logo_pracevliberci.svg": ["pracevliberci.cz"],
        "logo_pracevolomouci.svg": ["pracevolomouci.cz"],
        "logo_pracevostrave.svg": ["pracevostrave.cz"],
        "logo_pracevpardubicich.svg": ["pracevpardubicich.cz"],
        "logo_pracevplzni.svg": ["pracevplzni.cz"],
        "logo_pracevpraze.svg": ["pracevpraze.cz"],
        "logo_pracevusti.svg": ["pracevusti.cz"],
        "logo_pracevzline.svg": ["pracevezline.cz"],
    };

    for (const [logoName, domains] of Object.entries(data)) {
        if (domains.includes(domain)) {
            logo = logoName;
            break;
        }
    }
    return logo;
};
